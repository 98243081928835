<template>
  <section>
    <div class="row m-0">
      <MenuTaller />
      <loading class="col-lg-10 p-0" v-if="estadoloading" />
      <div
        class="col-lg-10 p-0"
        style="height: calc(100vh - 113px); overflow-y: scroll"
        v-else
      >
        <div class="col-12 row justify-content-between align-items-center mt-3">
          <div class="col-4">
            <div class="form-group">
              <label>Nombre</label>
              <input
                type="text"
                placeholder="Ejemplo: Taller de vocería"
                v-model="nombre"
                class="form-control form-control-sm"
                :class="errorNombre ? 'border border-danger' : ''"
              />
              <div class="invalid-feedback d-block" v-if="errorNombre">
                {{ errorNombreMessage }}
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="metodologia"
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >Metodologia nueva</label
                >
              </div>
            </div>
          </div>
          <div class="col-6 d-flex justify-content-center">
            <div class="mx-1">
              <button class="btn btn-sm btn-primary" @click="openModalAspecto">
                Crear Criterio
              </button>
            </div>
            <div class="mx-1">
              <button class="btn btn-sm btn-info" @click="openModalSubAspecto">
                Crear Sub Criterio
              </button>
            </div>
          </div>
          <div class="col-2 d-flex justify-content-center">
            <div class="mx-1">
              <button class="btn btn-sm btn-secondary" @click="volver()">
                Volver
              </button>
            </div>
            <div class="mx-1">
              <button
                class="btn btn-sm btn-success"
                v-if="!estado_editar"
                @click="guardar"
              >
                Guardar
              </button>
              <button
                class="btn btn-success btn-sm"
                type="button"
                disabled
                v-else
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div class="row col-12">
          <div class="col-12 d-flex justify-content-end">
            <div class="my-2 form-group">
              <input
                type="text"
                v-model="search"
                class="form-control form-control-sm"
                placeholder="Buscar por criterio"
              />
            </div>
          </div>
          <div
            class="invalid-feedback d-block col-12 text-center"
            v-if="errorListAspecto"
          >
            {{ errorListAspectoMessage }}
          </div>
          <div
            class="col-3 my-2"
            v-for="(aspecto, k) in aspectos.filter(
              (data) => !search || data.nombre.toLowerCase().includes(search)
            )"
            :key="aspecto.id"
          >
            <div class="card shadow-sm">
              <div class="card-body">
                <div class="row justify-content-between">
                  <div class="col-7">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :value="aspecto.id"
                        :id="'check' + aspecto.id"
                        v-model="array_aspecto"
                        @change="cambio(aspecto)"
                      />
                      <label
                        class="form-check-label"
                        :for="'check' + aspecto.id"
                        >{{ aspecto.nombre }}</label
                      >
                    </div>
                  </div>
                  <div class="col-5">
                    <p class>
                      <span
                        v-if="aspecto.modificar"
                        @click="openModalAspecto(aspecto)"
                      >
                        <font-awesome-icon
                          icon="pencil-alt"
                          class="text-primary fa-1x cursor-pointer"
                        />
                      </span>
                      <span
                        v-if="aspecto.modificar"
                        @click="openEliminar(aspecto.id)"
                      >
                        <font-awesome-icon
                          icon="trash-alt"
                          class="text-danger mx-2 fa-1x cursor-pointer"
                        />
                      </span>
                      <span @click="openDuplicar(aspecto)">
                        <font-awesome-icon
                          icon="copy"
                          class="text-secondary fa-1x cursor-pointer"
                        />
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-8 my-2" v-if="!aspecto.sinsub">
                    <button
                      :disabled="consulta"
                      class="btn btn-sm btn-secondary btn-block"
                      @click="open_add(aspecto.id)"
                    >
                      Ver
                    </button>
                  </div>
                </div>
                <div class="list_subaspect">
                  <ul>
                    <li
                      v-for="subaspecto in aspecto.sub_aspectos"
                      :key="subaspecto.id"
                    >
                      {{ subaspecto.nombre }}
                      <font-awesome-icon
                        v-if="aspecto.modificar"
                        icon="pencil-alt"
                        class="text-primary fa-1x cursor-pointer"
                        @click="openModalSubAspecto(subaspecto)"
                      />
                    </li>
                  </ul>
                </div>
                <div
                  class="row justify-content-center mt-1"
                  v-if="!aspecto.sinsub"
                >
                  <div class="col-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="aspecto.cuerpo"
                        :id="'check1' + aspecto.id"
                        disabled="true"
                      />
                      <label
                        class="form-check-label"
                        :for="'check1' + aspecto.id"
                        >Cuerpo</label
                      >
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="aspecto.voz"
                        :id="'check2' + aspecto.id"
                        disabled="true"
                      />
                      <label
                        class="form-check-label"
                        :for="'check2' + aspecto.id"
                        >Voz</label
                      >
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="aspecto.forma"
                        :id="'check3' + aspecto.id"
                        disabled="true"
                      />
                      <label
                        class="form-check-label"
                        :for="'check3' + aspecto.id"
                        >Fondo</label
                      >
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <p
                    class="text-center text-primary cursor-pointer"
                    @click="ver_definicion(k)"
                  >
                    Ver definicion
                  </p>
                  <p v-show="aspecto.ver_definicion">
                    {{ aspecto.definicion }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalAspecto ref="modalAspecto" />
    <modalSubAspecto ref="modalSubAspecto" />
    <modalDuplicar
      ref="modalDuplicar"
      :message="mensaje_duplicar"
      @duplicar="confirmar_duplicar"
    />
    <modal-eliminar ref="modalEliminar" @eliminar="eliminar" />
    <modalAdd ref="modalAdd" />
  </section>
</template>
<script>
import MenuTaller from "@/components/MenuTaller";
import loading from "@/components/Loading";
import modalAspecto from "@/components/modalAspectos";
import modalSubAspecto from "@/components/modalSubAspecto";
import modalDuplicar from "@/components/ModalDuplicar";
import modalAdd from "@/components/modalAnadirSubAspecto";

export default {
  name: "plantilla_editar",
  data() {
    return {
      copia: [],
      id_duplicar: "",
      mensaje_duplicar: "",
      search: "",
      consulta: false,
      array_aspecto: [],
      nombre: "",
      errorNombre: false,
      errorNombreMessage: "",
      errorListAspecto: false,
      errorListAspectoMessage: "",
      estado_editar: false,
      metodologia: "",
    };
  },
  components: {
    MenuTaller,
    loading,
    modalAspecto,
    modalSubAspecto,
    modalDuplicar,
    modalAdd,
  },
  async mounted() {
    const data = await this.$store.dispatch(
      "editPlantilla",
      this.$route.params.id
    );
    if (data) {
      this.nombre = data.DATA.nombre;
      this.metodologia = data.DATA.metodologia;
      this.array_aspecto = data.DATA.aspectos.map((item) => item.id);
    }
    this.$store.dispatch("fetchInfo");
  },
  computed: {
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
    aspectos() {
      this.copia = this.$store.getters.getInfo;
      return this.$store.getters.getInfo;
    },
    detalle_plantilla() {
      return this.$store.getters.getDetallePlantilla;
    },
  },
  watch: {
    nombre() {
      if (this.nombre != "") {
        this.errorNombre = false;
        this.errorNombreMessage = "";
      }
    },
    array_aspecto() {
      if (this.array_aspecto.length > 0) {
        this.errorListAspecto = false;
        this.errorListAspectoMessage = "";
      }
    },
  },
  methods: {
    openModalAspecto(dato = {}) {
      this.$refs.modalAspecto.open(dato);
    },
    openModalSubAspecto(dato = {}) {
      this.$refs.modalSubAspecto.open(dato);
    },
    openDuplicar(data) {
      this.id_duplicar = data.id;
      this.mensaje_duplicar = `¿Seguro que quieres duplicar el aspecto <strong>${data.nombre}</strong>?`;
      this.$refs.modalDuplicar.open();
    },
    openEliminar(id) {
      this.id_duplicar = id;
      this.$refs.modalEliminar.open();
    },
    async confirmar_duplicar() {
      let datos = {
        aspecto: this.id_duplicar,
      };
      const data = await this.$store.dispatch("duplicar", datos);
      if (_.has(data, "STATUS_CODE") && data.STATUS_CODE == 1) {
        const resp = await this.$store.dispatch("fetchInfoModal");
        if (_.has(resp, "STATUS_CODE") && resp.STATUS_CODE == 1) {
          this.$refs.modalDuplicar.open();
          this.id_duplicar = "";
          this.mensaje_duplicar = "";
        }
      }
    },
    async eliminar() {
      const data = await this.$store.dispatch(
        "eliminarAspecto",
        this.id_duplicar
      );
      if (_.has(data, "STATUS_CODE") && data.STATUS_CODE == 1) {
        const resp = await this.$store.dispatch("fetchInfoModal");
        if (_.has(resp, "STATUS_CODE") && resp.STATUS_CODE == 1) {
          this.$refs.modalEliminar.open();
          this.id_duplicar = "";
        }
      }
    },
    ver_definicion(index) {
      this.aspectos[index].ver_definicion = !this.aspectos[index]
        .ver_definicion;
    },
    async open_add(id) {
      this.consulta = true;
      const data = await this.$store.dispatch("query", id);
      if (data) {
        this.consulta = false;
        this.$refs.modalAdd.open();
      }
    },
    async guardar() {
      if (this.valid()) {
        this.estado_editar = true;
        let datos = {
          id: this.detalle_plantilla.id,
          nombre: this.nombre,
          metodologia: this.metodologia ? 1 : 0,
          aspectos: JSON.stringify(this.detalle_plantilla.aspectos),
          _method: "put",
        };
        await this.$store.dispatch("updatePlantilla", datos);
        this.$router.push({ name: "plantillas" });
        this.estado_editar = false;
      }
    },
    valid() {
      let contador = 0;
      if (this.nombre == "") {
        this.errorNombre = true;
        this.errorNombreMessage = "Debes ingresar un nombre a la plantilla";
        contador++;
      }
      if (this.array_aspecto.length == 0) {
        this.errorListAspecto = true;
        this.errorListAspectoMessage = "Debes seleccionar al menos un aspecto";
        contador++;
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    limpiar() {
      this.id_duplicar = "";
      this.mensaje_duplicar = "";
      this.search = "";
      this.consulta = false;
      this.array_aspecto = [];
      this.nombre = "";
      this.errorNombre = false;
      this.errorNombreMessage = "";
      this.errorListAspecto = false;
      this.errorListAspectoMessage = "";
    },
    volver() {
      this.limpiar();
      this.$router.push({ name: "plantillas" });
    },
    cambio(dato) {
      let valid = "";
      for (
        let index = 0;
        index < this.detalle_plantilla.aspectos.length;
        index++
      ) {
        const element = this.detalle_plantilla.aspectos[index];
        if (element.id == dato.id) {
          valid = index + 1;
        }
      }
      if (valid) {
        let resp = this.detalle_plantilla.aspectos[valid - 1];
        if (resp.nuevo == undefined) {
          resp.eliminar = !resp.eliminar;
        } else {
          this.detalle_plantilla.aspectos.splice(valid - 1, 1);
        }
      } else {
        if (dato.eliminar == undefined && valid) {
          this.detalle_plantilla.aspectos[valid - 1].eliminar = !this
            .detalle_plantilla.aspectos[valid].eliminar;
        } else {
          dato.nuevo = true;
          this.detalle_plantilla.aspectos.push(dato);
        }
      }
    },
  },
};
</script>
<style lang="css">
.list_subaspect {
  max-height: 15vh;
  overflow-y: scroll;
}
.list_subaspect::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.list_subaspect::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Cambiamos el fondo cuando esté en active */
.list_subaspect::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
</style>