<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modalAspecto">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{id!=""?'Editar':'Crear'}} Criterio</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="nombre">Nombre</label>
              <input
                type="text"
                id="nombre"
                class="form-control form-control-sm"
                v-model="nombre"
                :class="errorNombre ? 'border border-danger' : ''"
              />
              <div class="invalid-feedback d-block" v-if="errorNombre">{{ errorNombreMessage }}</div>
            </div>
            <div class="form-group">
              <label for="descripcion">Definicion</label>
              <textarea
                id="descripcion"
                class="form-control form-control-sm"
                rows="4"
                v-model="descripcion"
                :class="errorDescripcion ? 'border border-danger' : ''"
              ></textarea>
              <div
                class="invalid-feedback d-block"
                v-if="errorDescripcion"
              >{{ errorDescripcionMessage }}</div>
            </div>
            <!-- Nuevo-->
            <div class="form-group d-flex justify-content-center">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="defaultCheck1"
                  v-model="sin_subcretior"
                />
                <label class="form-check-label" for="defaultCheck1">Sin Subcriterio</label>
              </div>
            </div>
            <div v-if="sin_subcretior">
              <div class="form-group">
                <label for="evaluacion2">Evaluación</label>
                <input
                  type="text"
                  id="evaluacion2"
                  class="form-control form-control-sm"
                  v-model="evaluacion"
                  :class="errorEvaluacion ? 'border border-danger' : ''"
                  maxlength="100"
                />
                <span class="p-1 contador_limitador">{{evaluacion.length}} / {{limitador}}</span>
                <div
                  class="invalid-feedback d-block"
                  v-if="errorEvaluacion"
                >{{ errorEvaluacionMessage }}</div>
              </div>
              <div class="row align-items-center">
                <div class="col-10">
                  <div class="form-group m-0">
                    <label for="ejercicio2">Ejerccicion de fortalecimiento</label>
                    <input
                      type="text"
                      id="ejercicio2"
                      class="form-control form-control-sm"
                      v-model="ejercicio"
                      :class="errorAnadir ? 'border border-danger' : ''"
                      maxlength="100"
                    />
                    <span class="p-1 contador_limitador">{{ejercicio.length}} / {{limitador}}</span>
                    <div
                      class="invalid-feedback d-block"
                      v-if="errorAnadir"
                    >{{ errorAnadirMessage }}</div>
                  </div>
                </div>
                <div class="col-2">
                  <button class="btn btn-sm btn-secondary" @click="anadir">Añadir</button>
                </div>
                <div class="col-12 mt-3">
                  <div
                    class="invalid-feedback d-block text-center"
                    v-if="errorEjercicio"
                  >{{ errorEjercicioMessage }}</div>
                  <ul>
                    <li v-for="(list,k) in array_ejercicios" :key="k">
                      {{list}}
                      <font-awesome-icon
                        icon="trash-alt"
                        class="text-danger mx-2 fa-1x cursor-pointer"
                        @click="eliminar(k)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelar"
              :disabled="guardar_estado"
            >Cancelar</button>
            <button
              type="button"
              class="btn btn-success"
              @click="guardar"
              v-if="!guardar_estado"
            >Guardar</button>
            <button class="btn btn-success" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Guardando...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      sin_subcretior: "",
      nombre: "",
      errorNombre: false,
      errorDescripcionMessage: false,
      descripcion: "",
      errorDescripcion: false,
      errorDescripcionMessage: false,
      guardar_estado: false,
      evaluacion: "",
      ejercicio: "",
      array_ejercicios: [],
      errorDescripcionMessage: false,
      errorEvaluacion: false,
      errorEvaluacionMessage: false,
      errorEjercicio: false,
      errorEjercicioMessage: false,
      errorAnadir: false,
      errorAnadirMessage: "",
      limitador: 100,
    };
  },
  watch: {
    nombre() {
      if (this.nombre != "") {
        this.errorNombre = false;
        this.errorNombreMessage = "";
      }
    },
    descripcion() {
      if (this.descripcion != "") {
        this.errorDescripcion = false;
        this.errorDescripcionMessage = "";
      }
    },
    evaluacion() {
      if (this.evaluacion != "") {
        this.errorEvaluacion = false;
        this.errorEvaluacionMessage = "";
      }
    },
    array_ejercicios() {
      if (this.array_ejercicios.length > 0) {
        this.errorEjercicio = false;
        this.errorEjercicioMessage = "";
      }
    },
    ejercicio() {
      if (this.ejercicio != "") {
        this.errorAnadir = false;
        this.errorAnadirMessage = "";
      }
    },
  },
  methods: {
    open(dato) {
      if (dato.id != undefined) {
        this.id = dato.id;
        this.nombre = dato.nombre;
        this.descripcion = dato.definicion;
        this.sin_subcretior = dato.sinsub;
        this.evaluacion = dato.evaluacion;
        this.recomendacion = dato.recomendacion;
        this.array_ejercicios = JSON.parse(dato.ejercicios);
      }
      $("#modalAspecto").modal("toggle");
    },
    async guardar() {
      if (this.valid()) {
        this.guardar_estado = true;
        if (this.id == "") {
          let datos = {
            nombre: this.nombre,
            descripcion: this.descripcion,
            sinSub: this.sin_subcretior,
            evaluacion: this.evaluacion,
            recomendacion: this.recomendacion,
            ejercicios: JSON.stringify(this.array_ejercicios),
          };
          const data = await this.$store.dispatch("setAspecto", datos);
          if (_.has(data, "STATUS_CODE") && data.STATUS_CODE == 1) {
            const resp = await this.$store.dispatch("fetchInfoModal");
            if (_.has(resp, "STATUS_CODE") && resp.STATUS_CODE == 1) {
              this.cancelar();
            }
          }
        } else {
          let datos = {
            id: this.id,
            nombre: this.nombre,
            descripcion: this.descripcion,
            _method: "put",
          };
          const data = await this.$store.dispatch("updateAspecto", datos);
          if (_.has(data, "STATUS_CODE") && data.STATUS_CODE == 1) {
            const resp = await this.$store.dispatch("fetchInfoModal");
            if (_.has(resp, "STATUS_CODE") && resp.STATUS_CODE == 1) {
              this.cancelar();
            }
          }
        }
      }
    },
    valid() {
      let contador = 0;
      if (this.nombre == "") {
        this.errorNombre = true;
        this.errorNombreMessage = "Debes ingresar un nombre";
        contador++;
      }
      if (this.descripcion == "") {
        this.errorDescripcion = true;
        this.errorDescripcionMessage = "Debes ingresar una descripción";
        contador++;
      }

      if (this.sin_subcretior) {
        if (this.subcriterio == "") {
          this.errorSubCriterio = true;
          this.errorSubcriterioMessage = "Debes ingresar un nombre";
          contador++;
        }
        if (this.evaluacion == "") {
          this.errorEvaluacion = true;
          this.errorEvaluacionMessage = "Debes ingresar una evaluación";
          contador++;
        }
        if (this.array_ejercicios.length == 0) {
          this.errorEjercicio = true;
          this.errorEjercicioMessage =
            "Debes añadir ejercicios de fortalecimiento";
          contador++;
        }
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    cancelar() {
      $("#modalAspecto").modal("toggle");
      this.limpiar();
    },
    limpiar() {
      this.id = "";
      this.sin_subcretior = "";
      this.nombre = "";
      this.errorNombre = false;
      this.errorDescripcionMessage = false;
      this.descripcion = "";
      this.errorDescripcion = false;
      this.errorDescripcionMessage = false;
      this.guardar_estado = false;
      this.evaluacion = "";
      this.ejercicio = "";
      this.array_ejercicios = [];
      this.errorDescripcionMessage = false;
      this.errorEvaluacion = false;
      this.errorEvaluacionMessage = "";
      this.errorEjercicio = false;
      this.errorEjercicioMessage = "";
      this.errorAnadir = false;
      this.errorAnadirMessage = "";
    },
    valid_anadir() {
      let contador = 0;
      if (this.ejercicio == "") {
        this.errorAnadir = true;
        this.errorAnadirMessage = "El campo es obligatorio";
        contador++;
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    anadir() {
      if (this.valid_anadir()) {
        this.array_ejercicios.push(this.ejercicio);
        this.ejercicio = "";
      }
    },
    eliminar(posicion) {
      this.array_ejercicios.splice(posicion, 1);
    },
  },
};
</script>