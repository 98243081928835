<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modalAddSubAspecto">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="datos.aspecto != undefined">
              Agregar Sub Criterio a {{ datos.aspecto.nombre }}
            </h5>
          </div>
          <div class="modal-body d-flex justify-content-center">
            <div class="row align-items-end">
              <div class="col-10">
                <div class="row">
                  <div class="col-6">
                    <label>Sub Aspectos</label>
                    <div
                      class="invalid-feedback d-block"
                      v-if="errorSubAspecto"
                    >
                      {{ errorSubAspectoMessage }}
                    </div>
                    <select
                      class="form-control form-control-sm"
                      :class="errorSubAspecto ? 'border border-danger' : ''"
                      v-model="subaspecto"
                    >
                      <option value disabled selected>
                        Seleccione el sub aspecto
                      </option>
                      <option
                        v-for="sub_aspecto in datos.consulta"
                        v-if="!copia.includes(sub_aspecto.id)"
                        :key="sub_aspecto.id"
                        :value="sub_aspecto.id"
                      >
                        {{ sub_aspecto.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-6">
                    <label>Aplicar</label>
                    <div class="invalid-feedback d-block" v-if="errorTipo">
                      {{ errorTipoMessage }}
                    </div>
                    <select
                      class="form-control form-control-sm"
                      :class="errorTipo ? 'border border-danger' : ''"
                      v-model="tipo"
                    >
                      <option value disabled selected>
                        Seleccione el tipo aplicar
                      </option>
                      <option
                        v-for="tipo in tipos"
                        :key="tipo.id"
                        :value="tipo.id"
                      >
                        {{ tipo.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <button
                  class="btn btn-sm btn-success btn-block"
                  @click="anadir"
                  v-if="!guardar"
                  :disabled="eliminar_estado"
                >
                  Añadir
                </button>
                <button
                  class="btn btn-success btn-sm"
                  type="button"
                  disabled
                  v-else
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Guardando...
                </button>
              </div>
              <div
                class="col-12 mt-3 d-flex justify-content-center"
                v-if="datos.aspecto != undefined"
              >
                <div class="col-10 row justify-content-center">
                  <div class="col-6">
                    <label>Cuerpo</label>
                    <ul>
                      <li
                        v-for="(sub_aspecto, index) in datos.aspecto
                          .sub_aspectos"
                        :key="index"
                        v-if="sub_aspecto.pivot.subtipo == 1"
                      >
                        {{ sub_aspecto.nombre }}
                        <span v-if="!sub_aspecto.eliminar">
                          <font-awesome-icon
                            icon="trash-alt"
                            class="text-danger mx-2 fa-1x cursor-pointer"
                            @click="eliminar(sub_aspecto.id, 1)"
                          />
                        </span>
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                          v-else
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6">
                    <label>Voz</label>
                    <ul>
                      <li
                        v-for="(sub_aspecto, index) in datos.aspecto
                          .sub_aspectos"
                        :key="index"
                        v-if="sub_aspecto.pivot.subtipo == 2"
                      >
                        {{ sub_aspecto.nombre }}
                        <span v-if="!sub_aspecto.eliminar">
                          <font-awesome-icon
                            icon="trash-alt"
                            class="text-danger mx-2 fa-1x cursor-pointer"
                            @click="eliminar(sub_aspecto.id, 2)"
                          />
                        </span>
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                          v-else
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6">
                    <label>Forma</label>
                    <ul>
                      <li
                        v-for="(sub_aspecto, index) in datos.aspecto
                          .sub_aspectos"
                        :key="index"
                        v-if="sub_aspecto.pivot.subtipo == 3"
                        @click="eliminar(sub_aspecto.id, 3)"
                      >
                        {{ sub_aspecto.nombre }}
                        <span v-if="!sub_aspecto.eliminar">
                          <font-awesome-icon
                            icon="trash-alt"
                            class="text-danger mx-2 fa-1x cursor-pointer"
                          />
                        </span>
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                          v-else
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="open"
              :disabled="guardar"
            >
              cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      copia: [],
      tipos: [
        {
          id: "1",
          nombre: "Cuerpo",
        },
        {
          id: "2",
          nombre: "Voz",
        },
        {
          id: "3",
          nombre: "Forma",
        },
        {
          id: "4",
          nombre: "Cuerpo y Voz",
        },
        {
          id: "5",
          nombre: "Cuerpo y Forma",
        },
        {
          id: "6",
          nombre: "Voz y Forma",
        },
        {
          id: "7",
          nombre: "Cuerpo, Voz y Forma",
        },
      ],
      subaspecto: "",
      tipo: "",
      errorSubAspecto: false,
      errorSubAspectoMessage: "",
      errorTipo: "",
      errorTipoMessage: "",
      guardar: false,
      eliminar_estado: false,
    };
  },
  computed: {
    datos() {
      if (this.$store.getters.getConsulta.aspecto != undefined) {
        this.copia = this.$store.getters.getConsulta.aspecto.sub_aspectos.map(
          (item) => item.id
        );
      }
      return this.$store.getters.getConsulta;
    },
  },
  methods: {
    open() {
      $("#modalAddSubAspecto").modal("toggle");
    },
    async anadir() {
      if (this.valid()) {
        this.guardar = true;
        let datos = {
          aspecto: this.datos.aspecto.id,
          subaspecto: this.subaspecto,
          opcion: this.tipo,
        };
        const data = await this.$store.dispatch("addSubAspecto", datos);
        if (data) {
          this.subaspecto = "";
          this.tipo = "";
          await this.$store.dispatch("fetchInfoModal");
          await this.$store.dispatch("query", this.datos.aspecto.id);
          this.guardar = false;
        }
      }
    },
    async eliminar(subaspecto, tipo) {
      this.eliminar_estado = true;
      this.datos.aspecto.sub_aspectos.map((item) => {
        if (item.id == subaspecto) {
          item.eliminar = !item.eliminar;
        }
        return item;
      });
      let datos = {
        aspecto: this.datos.aspecto.id,
        subaspecto: subaspecto,
        opcion: tipo,
      };
      const data = await this.$store.dispatch("removeSubAspecto", datos);
      if (data) {
        this.subaspecto = "";
        this.tipo = "";
        await this.$store.dispatch("fetchInfoModal");
        await this.$store.dispatch("query", this.datos.aspecto.id);
        this.eliminar_estado = false;
      }
    },
    valid() {
      let contador = 0;
      if (this.subaspecto == "") {
        this.errorSubAspecto = true;
        this.errorSubAspectoMessage = "Seleccione un sub aspecto";
        contador++;
      }
      if (this.tipo == "") {
        this.errorTipo = true;
        this.errorTipoMessage = "Seleccione un tipo";
        contador++;
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
  },
};
</script>