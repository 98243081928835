<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="SubApecto">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{id==""?'Crear':'Editar'}} Sub Criterio</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="nombre_subaspecto">Nombre</label>
              <input
                type="text"
                id="nombre_subaspecto"
                class="form-control form-control-sm"
                v-model="nombre"
                :class="errorNombre ? 'border border-danger' : ''"
                maxlength="100"
              />
              <span class="p-1 contador_limitador">{{nombre.length}} / {{limitador}}</span>

              <div class="invalid-feedback d-block" v-if="errorNombre">{{ errorNombreMessage }}</div>
            </div>
            <div class="form-group">
              <label for="evaluacion">Evaluación</label>
              <input
                type="text"
                id="evaluacion"
                class="form-control form-control-sm"
                v-model="evaluacion"
                :class="errorEvaluacion ? 'border border-danger' : ''"
                maxlength="100"
              />
              <span class="p-1 contador_limitador">{{evaluacion.length}} / {{limitador}}</span>
              <div
                class="invalid-feedback d-block"
                v-if="errorEvaluacion"
              >{{ errorEvaluacionMessage }}</div>
            </div>
            <div class="row align-items-center">
              <div class="col-10">
                <div class="form-group m-0">
                  <label for="ejercicio">Ejerccicion de fortalecimiento</label>
                  <input
                    type="text"
                    id="ejercicio"
                    class="form-control form-control-sm"
                    v-model="ejercicio"
                    :class="errorAnadir ? 'border border-danger' : ''"
                    maxlength="100"
                  />
                  <span class="p-1 contador_limitador">{{ejercicio.length}} / {{limitador}}</span>
                  <div class="invalid-feedback d-block" v-if="errorAnadir">{{ errorAnadirMessage }}</div>
                </div>
              </div>
              <div class="col-2">
                <button class="btn btn-sm btn-secondary" @click="anadir">Añadir</button>
              </div>
              <div class="col-12 mt-3">
                <div
                  class="invalid-feedback d-block text-center"
                  v-if="errorEjercicio"
                >{{ errorEjercicioMessage }}</div>
                <ul>
                  <li v-for="(list,k) in array_ejercicios" :key="k">
                    {{list}}
                    <font-awesome-icon
                      icon="trash-alt"
                      class="text-danger mx-2 fa-1x cursor-pointer"
                      @click="eliminar(k)"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelar"
              :disabled="estado_guardar"
            >Cancelar</button>
            <button
              type="button"
              class="btn btn-success"
              @click="guardar"
              v-if="!estado_guardar"
            >Guardar</button>
            <button class="btn btn-success" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Guardando...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      nombre: "",
      errorNombre: false,
      errorDescripcionMessage: false,
      estado_guardar: false,
      evaluacion: "",
      errorEvaluacion: false,
      errorEvaluacionMessage: "",
      ejercicio: "",
      array_ejercicios: [],
      errorEjercicio: false,
      errorEjercicioMessage: "",
      estado_guardar: false,
      errorAnadir: false,
      errorAnadirMessage: "",
      limitador: 100,
    };
  },
  watch: {
    nombre() {
      if (this.nombre != "") {
        this.errorNombre = false;
        this.errorNombreMessage = "";
      }
    },
    evaluacion() {
      if (this.evaluacion != "") {
        this.errorEvaluacion = false;
        this.errorEvaluacionMessage = "";
      }
    },
    array_ejercicios() {
      if (this.array_ejercicios.length > 0) {
        this.errorEjercicio = false;
        this.errorEjercicioMessage = "";
      }
    },
    ejercicio() {
      if (this.ejercicio != "") {
        this.errorAnadir = false;
        this.errorAnadirMessage = "";
      }
    },
  },
  methods: {
    open(dato) {
      if (dato.id != undefined) {
        this.id = dato.id;
        this.nombre = dato.nombre;
        this.evaluacion = dato.evaluacion;
        this.recomendacion = dato.recomendacion;
        this.array_ejercicios =
          dato.ejercicios == null ? [] : JSON.parse(dato.ejercicios);
      }
      $("#SubApecto").modal("toggle");
    },
    async guardar() {
      if (this.valid()) {
        this.estado_guardar = true;
        if (this.id == "") {
          let datos = {
            nombre: this.nombre,
            evaluacion: this.evaluacion,
            recomendacion: this.recomendacion,
            ejercicio: JSON.stringify(this.array_ejercicios),
          };
          const data = await this.$store.dispatch("setSubAspecto", datos);
          this.cancelar();
        } else {
          let datos = {
            id: this.id,
            nombre: this.nombre,
            evaluacion: this.evaluacion,
            recomendacion: this.recomendacion,
            ejercicio: JSON.stringify(this.array_ejercicios),
            _method: "put",
          };
          const data = await this.$store.dispatch("updateSubAspecto", datos);
          if (data) {
            await this.$store.dispatch("fetchInfoModal");
            this.cancelar();
          }
        }
      }
    },
    valid() {
      let contador = 0;
      if (this.nombre == "") {
        this.errorNombre = true;
        this.errorNombreMessage = "Debes ingresar un nombre";
        contador++;
      }
      if (this.evaluacion == "") {
        this.errorEvaluacion = true;
        this.errorEvaluacionMessage = "Debes ingresar una evaluación";
        contador++;
      }
      if (this.array_ejercicios.length == 0) {
        this.errorEjercicio = true;
        this.errorEjercicioMessage =
          "Debes añadir ejercicios de fortalecimiento";
        contador++;
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    valid_anadir() {
      let contador = 0;
      if (this.ejercicio == "") {
        this.errorAnadir = true;
        this.errorAnadirMessage = "El campo es obligatorio";
        contador++;
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    cancelar() {
      $("#SubApecto").modal("toggle");
      this.limpiar();
    },
    limpiar() {
      this.id = "";
      this.nombre = "";
      this.errorNombre = false;
      this.errorDescripcionMessage = false;
      this.estado_guardar = false;
      this.evaluacion = "";
      this.errorEvaluacion = false;
      this.errorEvaluacionMessage = "";
      this.ejercicio = "";
      this.array_ejercicios = [];
      this.errorEjercicio = false;
      this.errorEjercicioMessage = "";
      this.estado_guardar = false;
      this.errorAnadir = false;
      this.errorAnadirMessage = "";
    },
    anadir() {
      if (this.valid_anadir()) {
        this.array_ejercicios.push(this.ejercicio);
        this.ejercicio = "";
      }
    },
    eliminar(posicion) {
      this.array_ejercicios.splice(posicion, 1);
    },
  },
};
</script>
<style lang="css">
.contador_limitador {
  font-size: 12px;
  background-color: #eee;
  float: right;
}
</style>